.ce_boatlist {
    @include border-box;

    > .ce_text {
        padding: 0 0 30px;
    }
}

@include screen(md, '.ce_boatlist') {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-right: -30px;

    > .ce_text {
        width: 50%;
        padding-left: 30px;
        padding-right: 30px;
    }

    .image_container {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        background-color: #eee;

        img {
            position: absolute;
            width: auto;
            height: auto;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: none;
        }
    }
}
