#social-feed {
    padding-top: 3em;
    padding-bottom: 2em;

    h2 {
        text-align: center;
    }

    .social-links {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2em;

        p {
            color: $ciCol;
            font-size: 1.4285714286em;
            font-weight: $fontweightBold;
            line-height: 40px;
            white-space: nowrap;
        }

        div {
            display: flex;
            margin-left: 12px;
        }

        a {
            display: block;
            width: 40px;
            height: 40px;
            margin: 0 8px;
            text-indent: -999em;

            &.facebook {
                background: url("../../images/social-facebook.svg") 0 0 no-repeat;
            }

            &.instagram {
                background: url("../../images/social-instagram.svg") 0 0 no-repeat;
            }

            &.youtube {
                background: url("../../images/social-youtube.svg") 0 0 no-repeat;
            }

            &.tiktok {
                background: url("../../images/social-tiktok.svg") 0 0 no-repeat;
            }

            &.linkedin {
                background: url("../../images/social-linkedin.svg") 0 0 no-repeat;
            }

            &.xing {
                background: url("../../images/social-xing.svg") 0 0 no-repeat;
            }
        }
    }

}

.arrow-link .mod_rocksolid_slider .btnBox {
    animation: 1s ease-in-out infinite alternate arrow-link;

    .button_more {
        position: relative;
        width: 40px;
        height: 20px;

        &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 17px;
            top: 1px;
            border-right: 3px solid #fff;
            border-bottom: 3px solid #fff;
            transform: rotateZ(45deg);
        }
    }
}

@keyframes arrow-link {
    0% {
        transform: translateX(0);
    }

    0% {
        transform: translateY(20px);
    }
}
