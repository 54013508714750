@import 'globals';

/*-- M A R K U P --*/
body,div,h1,h2,h3,h4,h5,h6,p,blockquote,pre,code,ol,ul,li,dl,dt,dd,figure,table,th,td,form,fieldset,legend,input,textarea {
    margin:0;
    padding:0;
}

html {
    overflow-y: scroll;
    height:100%;
}

body {
    font-size:100.01%;
    color: $fontCol;
    text-align: center;
    height:100%;
    background: #fff;
}

body > div {
    text-align: left;
    font:1.1em/1.4 unquote($ciFont);
    letter-spacing: $ciLetterSpacing;
    font-weight: 300;
}

table {
    border-spacing:0;
    border-collapse:collapse;
    font-size: 1em;
    width: 100%;
}
th, td, caption {
    text-align:left;
    text-align:start;
    vertical-align:top;
    padding: 6px 12px;
}
caption,th {
    font-weight:$fontweightBold;
}

ul {
    list-style-type:none;
}
sup {
    vertical-align:text-top;
    font-size: .6em;
    top: -.5em;
    position: relative;
}
sub {
    vertical-align:text-bottom;
    font-size: .7em;
    top: .4em;
    position: relative;
}
del {
    text-decoration:line-through;
}
ins {
    text-decoration:none;
}
abbr,acronym {
    font-variant:normal;
    border-bottom:1px dotted #666;
    cursor:help;
}
blockquote,q {
    quotes:none;
}
fieldset,img,iframe {
    border:0;
}
pre,code {
    font-family:Monaco,monospace;
}
input,button,textarea,select {
    font-family:inherit;
    font-size:99%;
    font-weight:inherit;
}
a {
    color: $fontCol;
    text-decoration: none;
}
a:hover {
    text-decoration: underline;
}
h1, .h1 {
    font-size:1.5em;
    line-height: normal;
    font-weight: 300;
}
h2, .h2, h1.ci {
    font-size:1.428571428571429em;
    line-height: normal;
    color:$ciCol;
    font-weight:$fontweightBold;
}
h3, .h3, h4, .h4 {
    font-size: 1em;
    font-weight: $fontweightBold;
    color: $headlineCol;
}

noscript {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: $fontweightBold;
    color: red;
}


/**
 * Default margins
 */
h1,h2,h3,h4,h5,h6,p,pre,blockquote,table,ol,ul,form,dl,video {
    margin-bottom: $lineHeight;
}
video {
    display: block;
}

/* ################ LAYOUTBUILDER ############### */
header,footer,nav,section,aside,article,figure,figcaption {
    display:block;
}

/**
 * Fix some positioning issues
 */
#container,.inside {
    position:relative;
}

/**
 * Apply the holy grail CSS layout if the screen is at least 768 pixel wide,
 * otherwise display all columns underneath each other
 *
 * @see http://www.alistapart.com/articles/holygrail
 */
#main,#left,#right {
    float:left;
    position:relative;
}
#main {
    width:100%;
}
#left {
    margin-left:-100%;
}
#right {
    margin-right:-100%;
}
#footer {
    clear:both;
}


/**
 * Flexible images (videos see #4896)
 *
 * @see http://webdesignerwall.com/tutorials/responsive-design-with-css3-media-queries
 */
img {
    max-width:100%;
    height:auto;
}

.ie7 img {
    -ms-interpolation-mode:bicubic;
}
.ie8 img {
    width:auto; /* see #5789 */
}

/**
 * Responsive audio (see #441)
 */
.audio_container audio {
    max-width:100%;
}

/**
 * Responsive videos (see #1348)
 */
.video_container video {
    max-width:100%;
    height:auto;
}
.aspect,
.responsive {
    position:relative;
    height:0;
}
.aspect iframe,
.responsive iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.aspect--16\:9,
.responsive.ratio-169 {
    padding-bottom:56.25%;
}
.aspect--16\:10,
.responsive.ratio-1610 {
    padding-bottom:62.5%;
}
.aspect--21\:9,
.responsive.ratio-219 {
    padding-bottom:42.8571%;
}
.aspect--25\:9,
.responsive.ratio-259 {
    padding-bottom:36%;
}
.aspect--4\:3,
.responsive.ratio-43 {
    padding-bottom:75%;
}
.aspect--3\:2,
.responsive.ratio-32 {
    padding-bottom:66.6666%;
}


/**
 * Format the Contao image galleries (now rendered as unordered lists)
 */
.ce_gallery ul {
    margin:0;
    padding:0;
    overflow:hidden;
    list-style:none;
}
.ce_gallery li {
    float:left;
}
.ce_gallery li.col_first {
    clear:left;
}

/**
 * Format tables
 */
.ce_table {
    th, td {
        border-left: 2px solid #fff;

        &:first-child {
            border: none;
        }
    }

    thead {
        th {
            color: #0062ad;
            font-weight: 600;
            background: #dad8cd;
        }
    }

    tbody {
        .odd {
            background: #d2dbf0;
        }
        .even {
            background: #f4f4f0
        }
    }

    &.schollenberg--character {
        .head_0,
        .col_0 {
            width: 75%;
        }

        .head_1,
        .col_1,
        .head_2 {
            width: 25%;
        }
    }

    &.schollenberg--products {
        .head_0,
        .col_0 {
            width: 40%;
        }

        .head_1,
        .col_1,
        .head_2,
        .col_2,
        .head_3,
        .col_3,
        .head_4,
        .col_4 {
            width: 15%;
        }
    }
}

/**
 * Clear floats
 */
.block {
    overflow:hidden;
}
.clear,#clear {
    height:0.1px;
    font-size:0.1px;
    line-height:0.1px;
    clear:both;
}

/**
 * Hide invisible elements
 */
.invisible {
    width:0;
    height:0;
    left:-1000px;
    top:-1000px;
    position:absolute;
    overflow:hidden;
    display:inline;
}

/**
 * Custom layout sections
 */
.custom {
    display:block;
}
#container:after,.custom:after {
    content:".";
    display:block;
    height:0;
    clear:both;
    visibility:hidden;
}
