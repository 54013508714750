@import 'globals';

/* responsive */
@media screen and (max-width: 599px){

}
@media screen and (min-width: 336px){
    .job_gallery {width: 50%;}
}

@media screen and (min-width: 480px){
    #mainNav h6 {
        top: 90px;
    }
    /* Standorte */
    #kibag_locations .fmodule_teaser {
        float:left;
        width:50%;
        margin-bottom:0;
    }

    .location_full .-large-col-4-1 {float:left;width:50%;}

    .job_header {
        font-size: 1.14286em;
    }

    /* Golfpark countdown */
    .cd_box {
        background: none;
        color: #fff;
        padding:0;
    }
    .cd_amount {
        display: inline-block;
    }
    .cd_unit {
        display:inline-block;
        font-weight:inherit;
    }
    .mod_newsreader .ce_gallery .cols_5 li {width:20%;box-sizing:border-box;}
}
@media screen and (max-width: 599px){
    .event_table table, .event_table tbody, .event_table tr, .event_table td {display:block;}
    .event_table thead {display:none}
    .event_table td {padding:0 12px;}
    .evt_date {padding-top:12px !important;}
    .evt_info {font-weight:bold}
    .evt_location {padding-bottom:12px !important;}
    /* .evt_location:before {content:'Ort: ';} */
}
@media screen and (min-width: 600px){
    .float_left {float:left;margin-right:60px;}
    .float_right {float:right;margin-left:60px;}

    /* Columns */
    .rs-column {
      float:left;
    }
    .-large-col-3-1 {width:33.3333333%}
    .-large-col-3-2 {width:66.6666667%}
    .-large-col-5-1, .-large-col-4-1 {width:50%;}
    .-large-col-5-1:nth-child(odd), .-large-col-4-1:nth-child(odd) {clear:left;}

    /* Spezial elemente */
    .textImg.default_layout {margin-left:-30px;margin-right:-30px;}

    .textImg {flex-direction: row}
    .textImg .image_container { width: 32.69230769230769%;margin-bottom:0;float:none !important;align-items:flex-start;flex-shrink:0}
    .textImg .txtBox {padding:$lineHeight 60px $lineHeight 30px;align-items:center;flex-grow:1}

    .colored .rs-columns {display:table;}
    .colored .rs-column {display:table-cell;float:none;}
    .colored .rs-column .image_container, .colored .rs-column > div {padding:0;}
    .colored .-large-col-3-1:first-child {padding:$lineHeight 30px $lineHeight 0;}
    .colored .-large-col-3-1:last-child {padding:$lineHeight 0 $lineHeight 30px;}

    .layout_latest {
        float: left;
        width: 50%;
    }

    /* Lernende */
    .job_gallery {width: 33.3333333333333%;}

    /* Referenzen */
    .references_teaser {
        margin-top:$lineHeight;
    }

    /* Standorte */
    #kibag_locations .fmodule_teaser {
        width:33.3333333333%;
    }

    .widget.w50,.form-widget.w50 {
        clear:none;
        float:left;
        width:49%;
    }
    .widget.w50 + .w50,.form-widget.w50 + .w50 {
        margin-left:2%;
    }
    .widget.firstCol {
        margin-left:0 !important;
    }

    /* Suche */
    .radio_container > span {
        float:left;
        margin-right: 20px;
    }
    .radio_container label {
        display:inline;
    }

    /*
    #footer [class^=ce_] {
        float:left;
        width:20%;
        box-sizing:border-box;
        padding:0 30px;
    }
    */

    /* Golfpark */
    .event h4, .event_date {float:left;}
    .event_date:before {content:', ';}
    .event_date + p {clear:left;}

    .headlineImg {
        padding-top: 1%;
    }
}

@media screen and (min-width: 768px){
    #topBar {
      text-align:left;
    }
    #langNav {
        float:right;
    }
    #topBar p, #topBar ul {
      margin-bottom:0;
    }
    #mainNav h6 {
            top: 50px;
        }
    /*.float_left, .float_right {float:none;}*/

    /* Columns */
    .-large-col-2-1 {width:50%}
    .-large-col-4-1 {width:25%;}

    /* Startseite  */
    .textImg_home, #portfolio .ce_text {margin-bottom:0 !important;padding-bottom:0;position:relative;overflow:hidden;}
    .textImg_home .image_container, #portfolio .image_container {
    position: absolute;
      top: 0;
      width: 780px;
    }
    .textImg_home .image_container.float_right, #portfolio .image_container.float_right {
        left: 53.01204819277108%;
        left:-webkit-calc(50% + 30px);
        left:-moz-calc(50% + 30px);
        left: calc(50% + 30px);
        margin-left:0;margin-right:0;
    }
    .textImg_home .image_container.float_left, #portfolio .image_container.float_left {
        right: 53.01204819277108%;
        right:-webkit-calc(50% + 30px);
        right:-moz-calc(50% + 30px);
        right: calc(50% + 30px);
        margin-right:0;margin-left:0;}
    .textImg_home .float_left, .textImg_home .float_right, #portfolio .float_left, #portfolio .float_right {float:none;}
    .textImg_home .txtBox, #portfolio .txtBox {display:table;height:550px;}
    .textImg_home .float_left + .txtBox, #portfolio .float_left + .txtBox {margin-left:50%;}
    .textImg_home .float_right + .txtBox, #portfolio .float_right + .txtBox {margin-right:50%;}
    .textImg_home .txtBox > div, #portfolio .txtBox > div {display:table-cell;vertical-align:middle}

    /* News */

    /* Referenzen */
    .references_teaser .image_container {
        width:45.76271186440678%;
        margin-left:60px;
        float:right;
    }

    /* Standorte */
    .location_full .-large-col-4-1 {float:left;width:33.3333333%;}

    .-large-col-4-1 {width:25%;}
    .-large-col-4-1:nth-child(odd) {clear:none;}

    /* Geotherm */
    .infogallery > div {
        float:left;
        width:50%;
    }

}

@media screen and (min-width: 800px){
    .layout_cols_m_r #main {float:left;width:66.666666666%}
    #right {float:left;width:33.33333333%;}

    /* Lernende */
    .location_label {display:inline;}

    /* Standorte */
    #kibag_locations .fmodule_teaser {
        width:25%; /*calc(25% - 1px);*/
    }
}

@media screen and (min-width: 960px) {
    .headlineImg img {
        width: 57.8%;
    }
}

@media screen and (max-width: 1023px){
    .active .level_2, .trail .level_2 {
        display:block;
        font-weight: normal;
        font-size:13px;
        margin-bottom:0;
    }
    .level_2 li {
        border-top:1px solid $borderColor;
    }
}

@media screen and (min-width: 1024px){
    #navBar {
        background-color:$gray;
        overflow:visible;
        text-align:center;
    }
    #mainNav .level_1 {
        display: inline-block !important;
    width: auto;
    }
    #mainNav {
    }
    #mainNav h6 {
      display:none;
    }
    #mainNav .level_1 > li {
      border-top:0;
      float:left;
      position:relative;
    }
    #mainNav a, #mainNav .active > .active {
        padding:10px 20px;
        border-left: 1px solid #f3f3f3;
        text-align:left;
    }
    #mainNav .level_2 {
      position:absolute;
      top:100%;
      left:0;
    width: 210px;
      z-index:1000;
      text-align:left;
      background:#fff;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    }
    .submenu:hover .level_2 {
        display:block;
    }
    a.sTrigger {
    text-indent:-1000px;
  }
  a.sTrigger:before
  {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;

      content: '\e802';
      position:absolute;
      left:510px;
      top:12px;
      color:$gray-font;

      /*display: inline-block;*/
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      /* opacity: .8; */

      /* For safety - reset parent styles, that can break glyph codes*/
      font-variant: normal;
      text-transform: none;

      /* fix buttons height, for twitter bootstrap */
      line-height: 1em;

      /* Animation center compensation - margins should be symmetric */
      /* remove if not needed */
      margin-left: .2em;

      /* you can be more comfortable with increased icons size */
      /* font-size: 120%; */

      /* Uncomment for 3D effect */
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
    a.sTrigger:hover:before
  {
      color:$ciCol;
      font-size:16px;
    }

    /* Spezial elemente */
    .colored .-large-col-3-1 {padding-top:0 !important;padding-bottom:0 !important;}

    /* News */
    .layout_latest {
        width: 33.33333333333%;
        padding:30px;
    }

    /* Referenzen */
    .references_teaser .image_container {
        width:33%;
    }

    /* Standorte */
    .location_full .-large-col-4-1 {float:left;width:25%;}

    /* Footer */
    .-large-col-5-1 {width:20%;}
    .-large-col-5-1:nth-child(odd) {clear:none;}
}

@media screen and (min-width: 1200px){
    /* Geotherm */
    .infogallery > div {
        width:33.333333333%;
    }
}

@media screen and (min-width: 1620px){
    .colored .rs-columns,#main .mod_article > .rs-columns {margin:0 auto;max-width:1620px;}
}
