$this: '.ce_yousty';

#{$this} {
    ul {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $borderColor;
    }

    li {
        border-bottom: 1px solid $borderColor;
    }

    a {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 10px 40px 10px 0;
        color: $gray-font !important;

        @include screen(md) {
            flex-direction: row;
        }

        &:hover {
            text-decoration: none;
            background: $gray-hover;
        }

        &::after {
            content: "";
            position: absolute;
            right: 2px;
            top: calc(50% - 4px);
            width: 6px;
            height: 6px;
            border-top: 2px solid $ciCol;
            border-right: 2px solid $ciCol;
            transform: rotateZ(45deg);
        }
    }

    .name {
        margin-right: 8%;
        color: $ciCol;
        font-weight: $fontweightBold;
    }

    .city {
        flex-grow: 1;
        color: $fontCol !important;
    }

    .years {
        display: flex;
        gap: 15px;
        color: $fontCol !important;

        .status {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 14px;

            &::before {
                content: "";
                width: 8px;
                height: 8px;
            }

            &--green::before {
                background: #02B374;
            }

            &--red::before {
                background: #E74746;
            }

            &--grey::before {
                background: $gray;
            }
        }
    }
}
