.text-image {
    @include border-box;

    @include screen(md) {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 1620px;
        overflow: visible;
        margin: 0 auto;

        .image_container {
            flex-grow: 1;
            margin-bottom: 0;
            margin-left: -30px;
        }

        .ce_text {
            flex-grow: 1;
            padding: 1em 0 1em 1em;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            .ce_text {
                padding-right: 1em;
                padding-left: 0;
            }

            .image_container {
                margin-left: 0;
                margin-right: -30px;
            }
        }

        &--21 {
            .image_container {
                width: 66%;
            }

            .ce_text {
                width: 33%;
            }
        }
    }

    @include screen(xl) {
        &--21 {
            &:nth-child(even) .ce_text {
                padding-left: 60px;
            }
        }
    }
}
