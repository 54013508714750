@import 'globals';

/*-- N A V I G A T I O N --*/
@media screen {
    .mod_navigation a {
        outline: none;
    }
    #mainNav a, #footer a {
        text-decoration: none;
    }
    #mainNav {
        font-size: .9em;
        font-weight: $fontweightBold;
        overflow: visible;
    }
    #mainNav h6 {
        position: absolute;
        top: 100px;
        right: 30px;
        width: 35px;
        border-top: 5px solid $borderColor;
        height: 25px;
        border-bottom: 5px solid $borderColor;
        text-indent: 10000px;
        cursor: pointer;
        -webkit-transition: border-color .1s;
        transition: border-color .1s;
    }
    #mainNav h6:after {
        content: '';
        border-top: 5px solid $borderColor;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
    }
    #mainNav h6:hover, #mainNav h6:hover:after {
        border-color: $ciCol;
    }
    #mainNav .level_1 {
        margin: 0;
        display: none;
        position: relative;
        vertical-align: top;
    }
    #mainNav .level_1 > li {
        border-top: 1px solid;
    }
    .ie7 #mainNav .level_1 > li {
        display: inline;
        zoom: 1;
    }
    #mainNav .level_1 > li.last {
        margin-right: 0;
    }

    #mainNav a, #mainNav .active > .active {
        display: block;
        text-align: center;
        padding: 10px 30px;
        color: $gray-font;
        -webkit-transition: background-color .2s, color .2s;
        transition: background-color .2s, color .2s;
        font-weight: 600;
    }
    #mainNav .active > .active, #mainNav a.trail {
        background-color: #e2e2e2;
        color: $ciCol;
    }

    #mainNav a.logout {
        color: #fff;
        background-color: #900;
    }

    #mainNav a:hover {
        color: $ciCol;
        background-color: $gray-hover;
    }
    .level_2 {
        display: none;
    }

    li.notfall {
        > a {
            color: $ciCol !important;

            > span {
                padding-left: 20px !important;
                background: url("../images/notfall.svg") 0 50%/20px 20px no-repeat;
            }
        }
    }

    li.notfall:hover,
    li.notfall.active {
        .level_2 {
            width: 210px;
            margin-left: auto;
            margin-right: auto;
            background: #fff;
            padding: 0 10px;
            color: $gray-font;
            font-weight: $fontweightBold;

            p {
                margin: 10px 0 !important;
                line-height: 1.2;
            }

            strong {
                color: $ciCol;
            }

            a {
                display: inline !important;
                padding: 0 !important;
                color: inherit !important;
                border: none !important;
            }

            img {
                width: 150px !important;
            }
        }
    }

    /* top */
    #topBar a[href^=tel] {
        color: #fff;
        text-decoration: none;
    }

    /* Search */
    a.sTrigger {
        overflow: hidden;
        position: relative;
    }

    header .mod_search {
        display: none;
    }
    #searchForm {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1000;
        color: white;
        text-align: center;
        background: $ciCol;
        background: rgba(0, 98, 173, 0.95);
        margin-bottom: 0;
    }
    #searchForm form {
        display: table;
        height: 100%;
        width: 100%;
    }
    #searchForm .formbody {
        display: table-cell;
        padding: 10px 30px;
        text-align: center;
        vertical-align: middle;
    }
    #searchForm label, #searchForm .submit {
        display: block;
        margin: 0 auto;
    }
    #searchForm input.text {
        border-width: 0 0 2px;
        background: none;
        color: #fff;
        width: 100%;
        text-align: center;
        font-size: 2em;
        line-height: normal;
        max-width: 420px;
        margin-bottom: 22px;
        -webkit-appearance: none;
        outline: 0;
    }
    #closeSearch {
        position: absolute;
        right: 0;
        top: 0;
        color: #fff;
        font-size: 12px;
        line-height: 30px;
        padding: 0 30px 10px;
    }
    #searchForm button.submit {
        color: #fff;
        padding: 9px;
        font-weight: $fontweightBold;
        border: 2px solid #fff;
        background-color: transparent;
        -webkit-transition: background-color 2s;
        transition: background-color 2s;
        cursor: pointer;
    }
    #searchForm button.submit:hover {
        background: #fff;
        color: $ciCol;
    }
    /* Search results */
    #main .mod_search > div {
        margin-top: $lineHeight;
        border-top: 1px solid #ddd;
        padding-top: $lineHeight;
    }
    .mod_search .highlight {
        background: $gray;
        padding: 0 5px;
    }
    .button_more a:hover {
        background: #fff;
        color: $ciCol;
        border: 1px solid $ciCol;
        padding: 5px 9px;
        text-decoration: none;
    }


    #langNav {
        ul {
            display: flex;
        }

        li {
            padding: 0 10px;
            border-left: 1px solid #5989AD;

            &:first-child {
                border-left: none;
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        a {
            color: #fff;
            text-transform: uppercase;
        }
    }

    /* News */
    .pagination li {
        float: left;
        border: 1px solid $gray;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 1px;
    }
    .pagination a, .pagination .active {
        width: 30px;
        line-height: 30px;
        text-align: center;
        display: block;
        color: $fontCol;
    }
    .pagination a:hover {
        background: $gray-hover;
        text-decoration: none;
    }
    .pagination .active {
        background: $ciCol;
        color: #fff;
    }
    .pagination .next, .pagination .previous {

    }
    /*
    .pagination .next, .pagination .previous {
        text-indent:-1000px;
        position:relative;
    }.pagination .next:after, .pagination .previous:after {
        content:'<';
        position:absolute;
        top:0;
        left:0;
        width:30px;
        lline-height:30px;
    }*/

    /* Footer */
    #footer a {
        text-decoration: none;
    }
    #footer a:hover {
        text-decoration: underline;
    }
    #footerNav li {
        display: inline-block;
        margin-left: 0;
        vertical-align: top;
    }
    #footerNav li:not(.first):before {
        content: '|';
        margin: 0 10px;
    }


    #topLink {
        background-color: $ciCol;
        display: none;
        width: 50px;
        height: 50px;
        position: fixed;
        right: 15px;
        bottom: 15px;
        text-indent: -10000px;
        border-radius: 2px;
        opacity: .7;
        z-index: 1000;
        outline: 0;
    }
    #topLink:hover {
        opacity: 1;
    }
    #topLink::after {
        border-right: 2px solid #fff;
        border-top: 2px solid #fff;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        margin-left: 20px;
        transform:rotate(-45deg);
        /*behavior: url(Pfad/PIE.htc);*/
    }

    .backLink {
        background-color: $ciCol;
        position: fixed;
        left: 0;
        bottom: 50%;
        margin-bottom: -25px;
        width: 28px;
        height: 50px;
        text-indent: -10000px;
        border-radius: 0 2px 2px 0;
        opacity: .7;
        z-index: 1000;
        outline: 0;
    }
    .backLink:hover {
        opacity: 1;
    }
    .backLink::after {
        border-right: 2px solid #fff;
        border-top: 2px solid #fff;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        margin: -2px 0 0 10px;
        transform:rotate(-135deg)
    }
}
