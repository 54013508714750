@import 'globals';

@media screen {

    /* -- M A I N -- */
    #main a, #right a {color:$ciCol;}
    .ce_text ul {
      list-style:disc;
      padding-left:20px;
      overflow:hidden;
    }
    .ce_text ul li {
        padding-left:5px;
    }
    #main ol li {
        margin-left: 28px;
    }

    /* Bilder */
    .image_container {
        margin: 0 0 1.5em;
    }
    #header .image_container {
        margin: 0;
    }
    #header img {
        width:100%;
    }
    #header video {
        min-width: 100%;
        min-height: calc(100vh - 150px);
    }
    #header .ce_rsce_hero video {
        max-width: 100%;
        min-height: 0;
    }
    .image_container img, .box img {
        display: block;
    }
    .image_container .image_title {
        position: relative;

        img {
            width: 100%;
        }

        p {
            position: absolute;
            left: 0;
            right: 0;
            top: 49%;
            padding: 0 5px 10px;
            background: rgba(255, 255, 255, .75);
            color: $ciCol;
            font-size: 8vw;
            line-height: 0.9;
            letter-spacing: .8vw;
            font-weight: $fontweightBold;
            text-align: center;
            transform: translateY(-50%);
            pointer-events: none;

            @media screen and (min-width: 600px) {
                font-size: 2.8vw;
                letter-spacing: 0.25vw;
            }

            @media screen and (min-width: 1620px) {
                font-size: 50px;
                letter-spacing: 3px;
            }
        }
    }
    .image_container .caption {
        padding-top:10px;
        font-size: 13px;
        line-height:normal;
        width: 100% !important;
    }
    .ce_image a {
        text-decoration:none;
    }
    .ce_image img {
        width: 100%;
    }

    #main a:hover img {opacity:.6}

    .ce_gallery, .team_gallery {
        margin:0 -7px;
    }
    .ce_gallery li, .box {
        float:left;
        padding: 0 7px;
    }
    .cols_4 li, .box {
        box-sizing:border-box;
        width:50%;
    }
    .ce_player, .ce_youtube {margin-bottom:1.5em}

    /* Galerien */

    /* Slider */
    #slider-full, .ce_sliderStart {position:relative;}
    #slider-full h2 {font-size:2em;color:#fff;background-color:rgba(0,0,0,0.45);display:inline-block;padding:5px 20px;margin-bottom:3px;}
    #slider-full p {color:#fff;background-color:rgba(0,0,0,0.45);display:inline-block;padding:5px 20px;}
    #slider-full .posBox {position:absolute;top:0;left:0;width:100%;}
    #slider-full .centerBox {text-align:center;margin-top:18%;}
    /*.slider-wrapper {height:0;padding-bottom:28.6875%}*/
    .slider-control {height:auto;position:static;opacity:0;-webkit-transition: opacity .5s;transition: opacity .5s;}
    .ce_sliderStart:hover .slider-control {opacity:1}
    .rsts-next, .rsts-prev, .slider-control .slider-next, .slider-control .slider-prev {position:absolute;display:block;width:48px;top:50%;margin-top:-24px;background:rgba(0,0,0,0.4);font-size:3em;line-height:40px;padding-bottom:10px;text-align:center;color:#fff;text-decoration:none;-webkit-transition: background-color .2s;transition: background-color .2s;}
    .rsts-prev, .slider-control .slider-prev {left:20px;}
    .rsts-next, .slider-control .slider-next {right:20px;}
    .slider-next:hover, .slider-prev:hover {background-color:rgba(0,0,0,0.6);}
    .slider-menu {position:absolute;bottom:20px;}
    .slider-control .slider-menu {
      font-size: 36px;
      left: 0;
      margin-left: 0;
      text-align: center;
      top: unset;
      width: 100%;
    }

    .rsts-skin-default .rsts-next, .rsts-skin-default .rsts-prev {width:48px !important;height:48px !important}
    .rsts-skin-default .rsts-nav {
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
    }
    .rsts-skin-default .rsts-nav-bullets a {
        width: 14px;
        height: 14px;
        background-color: unset;
        border:2px solid #fff;
        background-clip:unset;
        -webkit-box-shadow:unset;
        -moz-box-shadow:unset;
        box-shadow:unset;
    }
    .rsts-skin-default .rsts-nav-bullets a.active {
        background-color: #fff;
    }
    .rsts-next, .rsts-prev, .rsts-nav {
        opacity:0;
      -webkit-transition: opacity .5s;
      transition: opacity .5s;
    }
    .mod_rocksolid_slider:hover .rsts-next, .mod_rocksolid_slider:hover .rsts-prev, .mod_rocksolid_slider:hover .rsts-nav { opacity:1;}


    #slider-full .mod_rocksolid_slider {
        min-height: 300px;
    }
    #slider-full .mod_rocksolid_slider {
        height: 100%;
        overflow: hidden;
    }
    #slider-full .rsts-view {
        max-height: 100% !important;
        overflow: hidden;
    }
    #slider-full .rsts-crop {
        /*
        width: 100%;
        height: 0 !important;
        padding-bottom:100%;
        overflow:hidden;
        */
    }
    #slider-full .posBox {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: table;
        height: 100%;
    }
    #slider-full .centerBox {
        margin-top: 0 !important;
        display: table-cell;
        vertical-align:middle;
    }
    .uf_fullSlider .image_container {
      left: 50%;
      margin: 0;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .uf_fullSlider .ce_image {
      display:none;
    }
    .uf_fullSlider .ce_image.active {
      display:block;
    }
    .uf_fullSlider .rsts-prev, .uf_fullSlider .rsts-next {
      background-clip: padding-box;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAilBMVEUHBwg/QEF5enp5ensCAwQHCAhzdHUAAQEGBwh8fX37+/svMDBub3COjo9yc3NtbW709fWvsLDm5uba2trR0dEuLi6Pj4/Q0NDl5eXZ2toFBgfz8/MuLy/09PT29vb8/Pz6+vp7e3x0dXZzdHT4+Pj39/f9/v3+/v79/f3///8EBQb5+fkAAAD19fVMP6q+AAAALnRSTlOAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8hTP8QAAAJ1JREFUeNrV0lkPwiAMAOB53zrva2xOxQm0///vWZ4a1o3EN20gNPnSlFASRKVQIS2KFHPKaPvcH8St8WesXYzddVMIZn3NZ1tTZ9Y7QLa7MdfVWlgYZqk9lzML7VZPbGCdDsFekpI14GoN/fGoY6aqkbVaUvVg0lKN7v3wvcuwt/Tg5tIPRjA77I/i1dhX2dnEJnYq4vP+2a/4FX8AsSOXytKixMYAAAAASUVORK5CYII=");
      background-position: 50% 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      bottom: 15px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
      height: 36px;
      line-height: 0;
      outline: medium none;
      overflow: hidden;
      position: absolute;
      right: 55px;
      text-align: center;
      text-decoration: none;
      text-indent: -9999px;
      transition: opacity 0.05s linear 0s;
      width: 44px;
    }
    .uf_fullSlider .rsts-next {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAkFBMVEUBAQLPz9AlJiYlJifS0tIsLS0nJyjQ0dDR0tIBAQElJSYrLC2tra39/PwFBQYFBgf6+fmBgYKCgoL9/f339vZBQULIycnIyMiCg4PR0dEmJycmJyjMzMz8/Pzl5eXj4+MCAwQGBwghIiP09PT7+/shIiL6+vrNzs7Nzc0iIyP29vb39/cHCAkAAAD////19fV/WdL3AAAAMHRSTlOAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICRnvRdAAAAnklEQVR42tXSxw7CMAwG4LL3XqUtLdRQqNPY7/92WEJVDhkSN7B8iPTpl6M4ETMAA0t/ChmwPTELe+uPGKsQKywWflYUJxn5eI9xpOsneXidp7pp3Wa+dHrGbWY6d43bLD673nR9vLsZaL5cNfoAyp0eDyRdntxp6icy+/Ei981HqVGLq3xo1GaFm4kuRb1vvp1mFNzYLrzvn/2KX/MbXNOe099MDj0AAAAASUVORK5CYII=");
      right: 20px;
    }
    .uf_fullSlider .rsts-nav {
    position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
    }
    .rsts-nav ul {
    margin-bottom: 0;
    }
    .uf_fullSlider .rsts-nav li {
      display: inline;
    }
    .uf_fullSlider .rsts-nav-bullets a {
      border-radius: 100%;
      display: inline-block;
      margin: 12px 4px;
      outline: medium none;
      overflow: hidden;
      text-align: left;
      text-indent: -10000px;
      height: 10px;
      width: 10px;
      border: 2px solid #fff;
    }
    .uf_fullSlider .rsts-nav-bullets a:hover, .uf_fullSlider .rsts-nav-bullets a.active {
      background-color: #777777;
      box-shadow: 0 2px 0 -1px rgba(255, 255, 255, 0.5) inset, 0 -2px 0 -1px rgba(0, 0, 0, 0.3) inset, 0 0 0 1px #555555;
    }
    .uf_fullSlider .rsts-nav-bullets a.active {
      background-color: #fff;
    }
    .uf_fullSlider h2, .uf_fullSlider .txt {
        opacity:0;
       -webkit-transition: opacity 0.5s linear,-webkit-transform 0.5s ease-out;
    -moz-transition: opacity 0.5s linear,-moz-transform 0.5s ease-out;
    -o-transition: opacity 0.5s linear,-o-transform 0.5s ease-out;
    transition: opacity 0.5s linear,transform 0.5s ease-out;
    -webkit-transform: translate(0,-100%);
    -moz-transform: translate(0,-100%);
    -ms-transform: translate(0,-100%);
    -o-transform: translate(0,-100%);
    transform: translate(0,-100%);
    }
    .run-in .txt, .run-in h2 {
        -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;
    }
    .uf_fullSlider .btnBox {
        opacity:0;
       -webkit-transition: opacity 0.5s linear,-webkit-transform 0.5s ease-out;
    -moz-transition: opacity 0.5s linear,-moz-transform 0.5s ease-out;
    -o-transition: opacity 0.5s linear,-o-transform 0.5s ease-out;
    transition: opacity 0.5s linear,transform 0.5s ease-out;
    -webkit-transform: translate(0,100%);
    -moz-transform: translate(0,100%);
    -ms-transform: translate(0,100%);
    -o-transform: translate(0,100%);
    transform: translate(0,100%);
    }
    .run-in .btnBox {
        -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity:1;
    }



    /* Accordions */
    .ce_accordionSingle {
    }
    .ui-accordion-header {
        border-top: 1px solid #ddd;
        font-size: 1.14286em;
        line-height: 42px;
        cursor:pointer;
        line-height: normal;
        color: #0062ad;
        font-weight: $fontweightBold;
        padding:10px 8px 11px;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
    }
    .ui-accordion-header:hover {
        background-color:$gray-hover;
    }
    .accordion {
        padding:11px 8px 0;
    }
    .ce_accordionSingle.last {
        margin-bottom: 22px;
        border-bottom: 1px solid #ddd;
    }
    .ui-accordion-header:after {
        content:'+';
        float:right;
    }
    .ui-accordion-header:after {
        content:'+';
        float:right;
    }
    .ui-accordion-header-active:after {
        content:'-';
    }

    /* jobs */
    .job_teaser {
        border-top: 1px solid #ddd;
    }
    .job_teaser a {
        display:block;
        padding:10px 15px 11px 8px;
        position:relative;
        -webkit-transition: background-color .2s;
      transition: background-color .2s;
    }
    .job_header {
        font-size: 1em;
        line-height: normal;
        color: #0062ad;
        font-weight: $fontweightBold;
        margin-bottom:0;
    }
    .job_teaser a:after {
        content:'\203A';
        position:absolute;
        top:50%;
        right:8px;
        margin-top:-14px;
        font-size:2em;
    }
    .job_teaser a:hover {
        text-decoration:none;
        background-color:$gray-hover;
    }
    .job_location {
        display:block;
        margin-bottom:0;
        font-size:12px;
        color:$fontCol
    }
    .job_teaser.last{
        border-bottom: 1px solid #ddd;
    }
    .job_footer {
        margin:$lineHeight*0.5 0 $lineHeight;
        border-top: 1px solid #ddd;
        padding:32px 0 10px;
        border-bottom: 1px solid #ddd;
    }

    /* lernende */
    #trainee-offers .mod_fmodule_fe_list{margin:0 -6px;}
    .job_gallery {
        float: left;
        width: 100%;
        padding: 6px;
        box-sizing:border-box;
    }
    .job_gallery > a {
        display: block;
        border: 1px solid #0062ad;
        position: relative;
        padding-bottom:26px;
    }
    .job_gallery > a > .image_container {
        display: block;
        margin-bottom:0;
    }
    .job_gallery .job_header {
        color: #fff;
        background: #0062ad;
        text-align: center;
        position: absolute;
        bottom:26px;
        left:0;
        right:0;
        height:54px;
        font-size: 16px;
        padding:0 6px;
        display: none;
        align-items: center;
        justify-content: center;
    }
    .job_gallery .job_location {
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        padding:2px 0;
        text-align:center;
    }
    .location_label {display:none;}

    /* Parallax */
    .parallax {
      margin: 36px -30px 60px !important;
      padding-top: 60px;
      background: rgba(0, 0, 0, 0) url("../images/baustoffe_big.jpg") no-repeat fixed 0 0;
      background-size: cover;
      text-align: center;
      color:#fff;
      padding-bottom: 36px;
    }
    .parallax h2 {
     color:#fff;
      font-size:1.75em;
    }

    /* News */
    time {white-space:nowrap;}
    /*
    .layout_latest {
        margin:0 0 1.5em 6%;
        width:29.33333333333%;
        float:left;
    }

    .layout_latest.first {
        margin-left:0;
    }
    */
    #news_home .mod_newslist {margin:0 -30px;}
    .layout_latest {
        box-sizing:border-box;
        padding:0 30px;
        margin-bottom:1.5em;
    }
    .layout_latest .image_container {
        margin-bottom: 1.5*$lineHeight;
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-bottom: 78.54166666666667%;
    }
    .layout_latest .imgLink, .layout_list .imgLink  {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .layout_latest img, .layout_list img {
        width: 100%;
    }
    .layout_latest h2 {
        min-height:3em;
    }
    .layout_latest .info, .layout_list .info {
        font-size:0.8571428571428571em;
        margin-bottom:11px
    }
    .layout_latest .teaser {
        position: relative;
        max-height: 88px;
        overflow: hidden;
    }
    .layout_latest .teaser:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,1));
        background: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,1));
    }
    .mod_newsreader .ce_gallery li.col_first {clear:none;}
    .mod_newsreader .ce_gallery .cols_5 li {width:33.33333333%;box-sizing:border-box;}
    .layout_latest .tagged {
        font-size:0.8571428571428571em;
        margin: -$lineHeight 0 0;
        max-height: $lineHeight;
        overflow: hidden;
    }
    .tagged li {
        display:inline;
    }
    .tagged a {
        color:$fontCol !important;
    }
    .tagged li:after {
        content:',';
    }
    .tagged li:last-child:after {
        content:'';
    }


    .layout_list {
        margin-bottom:22px;
    }
    .layout_list h2, .layout_latest h2 {
        margin-bottom:5px;
    }
    .layout_latest .teaser p, .layout_list .teaser p {
        margin-bottom:11px;
    }
    .layout_list .image_container {
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-bottom: 40.27027027027027%; /*56.21621621621622%;*/
    }
    .pagination {
        padding-top: 11px;
        font-size:0.8571428571428571em;
        line-height:30px;
        margin:-1px 0 0;
    }
    .pagination p {
        float:right;
    }

    .layout_simple .image_container {
        float:left,;
        margin:0 15px 0 -51px;
    }
    .layout_simple {
        font-size: 0.8571428571428571em;
        line-height:1.5em;
        margin-bottom:1.5em;
        padding-left: 51px;
    }
    .mod_cleverreach_list .layout_simple {
        padding-left: 0;
    }
    .newsTxt > a {
        font-weight:$fontweightBold;
        color:$ciCol;
    }

    .layout_full h1 {
        font-size:1.428571428571429em;
        line-height: normal;
        color:$ciCol;
        font-weight:$fontweightBold;
    }
    .layout_full > .image_container {
        position: relative;
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-bottom: 56.21621621621622%;
    }
    .layout_full > .image_container > img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .layout_list.no_img .info {
        margin: 0;
    }
    .layout_simple.no_img {
        padding: 0 !important;
    }
    .empty {margin-bottom:$lineHeight;}

    /* Blog */
    .layout_list.no_img .info {
        margin-bottom: 0;
    }

    /* Fotogalerien
    .layout_latest.arc_9 .image_container {
        margin-bottom: 1.5em;
    }
    .layout_list.arc_9 {
        margin-bottom: 3em;
    }
    .layout_list.arc_9 h2 {
        display:table-header-group;
        padding-bottom:5px;
    }
    .layout_list.arc_9 h2 > a {
        display:inline-block;
        padding-bottom:5px;
    }
    .layout_list.arc_9 .image_container {
        display:table-footer-group;
    }
     */
    .layout_list.arc_9 .more, .foto_arc .more {
        display: none;
    }

    /* Spezial elemente */
    .textImg_home {border-bottom:1px solid $gray;margin:0 -30px;}
    .textImg_home, #portfolio .ce_text {overflow:visible;/*margin-bottom:$lineHeight;*/padding-bottom:$lineHeight}
    .textImg_home .txtBox > div {padding:0 30px;}
    .textImg_home .image_container.float_left, #portfolio .image_container.float_left {margin-left:-30px;}
    .textImg_home .image_container.float_right, #portfolio .image_container.float_right {margin-right:-30px;}

    /*
     #portfolio .ce_text {margin:0 -30px;}
        #portfolio .image_container {width:48.07692307692308%;}
        #portfolio .float_left + .txtBox {display:table;height:100%;padding:0 30px 0 60px;vertical-align:middle}
        #portfolio .float_right + .txtBox {display:table;height:100%;padding-left:0 60px 0 30px;vertical-align:middle}
    */



    /* Maps */
    .ce_dlh_googlemaps,.location_gmap_container {
      height: 0;
      padding-bottom: 100%; /* H x 100 /B */
      position: relative;
      margin-bottom:18px;
    }
    .ce_dlh_googlemaps.map_golfpark {
      padding-bottom: 50%; /* H x 100 /B */
    }
    .google_map {
      height: 0;
      padding-bottom: 52.63157894736842%; /* H x 100 /B */
      position: relative;
    }
    .dlh_googlemap,.location_gmap /*, .mejs-container*/ {
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100% !important;
    }
    /*
    .google_map img { max-width: none !important; }
    */


    /* Kontakt */
    .ce_form {
        overflow:visible;
    }
    .widget {
        clear:left;
    }
    label {display:block;}
    .ce_form input:not(.submit),.ce_form select,.ce_form textarea, .mod_login input:not(.submit) {
        padding:10px;
        margin-bottom: 11px;
        border: 1px solid $borderColor;
        box-sizing:border-box;
        width:100%;
        -webkit-transition: background-color 0.5s, border-color 0.5s, box-shadow 0.5s;
    -moz-transition: background-color 0.5s, border-color 0.5s, box-shadow 0.5s;
    -o-transition: background-color 0.5s, border-color 0.5s, box-shadow 0.5s;
    transition: background-color 0.5s, border-color 0.5s, box-shadow 0.5s;
    }
    select {
        -webkit-appearance:none;
    }
    .ce_form input:not(.submit):focus, .ce_form textarea:focus, .mod_login input:not(.submit):focus  {
        border-color: $ciCol;
        box-shadow:0 0 2px $ciCol;
        background-color:$gray-hover;
    }

    .captcha_text {
        display:block;
        margin-bottom:$lineHeight;
    }
    .ce_form .submit, .mod_login .submit  {display:inline-block;padding:5px 9px;font-size:14px;color:#fff;background-color:$ciCol;border:1px solid $ciCol}
    .ce_form .submit:hover, .mod_login .submit:hover {color:$ciCol;background-color:#fff;}
    /*
    form {text-align:left;}
    fieldset {
        padding:0 20px;
    }
    input,select,textarea {
        padding:10px 6px;
        margin-bottom: 9px;
        border-color: #fff !important;
        @include radius(0 !important);
    }

    textarea {
        max-width: 100%;
        min-width: 100%;
        height: 121px;
        padding: 3px 6px;
    }
    input[type="submit"], .button {
        margin-top: 10px;
        width:150px;
        padding:0;
        background-color: #62b5e5;
        background-image: none;
        border:none;
        color: #fff;
        font-weight:$fontweightBold;
        font-size:1.25em;
        line-height:1.5em;
            @include radius(0 !important);
        float:right;
        -webkit-appearance: none;
    }
    input[type="submit"]:hover, .button:hover {
        background-color: #6495B2;
    }
    select {
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border: 1px solid #B0B9C6;
          width:50%;
          padding: 3px;
          box-shadow: 0 1px 1px #EEEEEE inset;
    }
    */
    .ce_form {
        .checkbox_container,
        .radio_container {
            display: flex;
            flex-direction: column;
            margin-bottom: 11px;
        }

        input[type="radio"],
        input[type="checkbox"] {
            all: unset;
            position: absolute;
            visibility: hidden;

            + label {
                position: relative;
                display: inline-block;
                padding-left: 30px;
                margin: 5px 0;

                &::before {
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 0;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border: 2px solid $ciCol;
                }
            }

            &:checked + label {
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                }
            }
        }

        input[type="radio"] {
            + label {
                &::before,
                &::after {
                    border-radius: 50%;
                }

                &::after {
                    top: 7px;
                    left: 4px;
                    width: 11px;
                    height: 11px;
                    background: $ciCol;
                }
            }
        }

        input[type="checkbox"] {
            + label {
                &::after {
                    top: 5px;
                    left: 6px;
                    width: 5px;
                    height: 10px;
                    border-bottom: 2px solid $ciCol;
                    border-right: 2px solid $ciCol;
                    transform: rotateZ(45deg);
                }
            }
        }
    }

    .widget.w50.firstCol {clear:left}
    .column_container {overflow:hidden;}
    input.captcha {
        margin-bottom: 0;
    }
    p.error {
      color: red;
      margin-bottom: 0;
    }

    /* Search */
    #main .mod_search input.text {
      background:none;
      width:100%;
      box-sizing:border-box;
      margin-bottom:22px;
      -webkit-appearance: none;
      outline:0;
      line-height:3em;
      padding:1px 97px 1px 14px;
      border: 1px solid $borderColor;
    }
    #main .mod_search .submit {
      background:none;
      -webkit-appearance: none;
      outline:0;
      line-height:3em;
      padding:0 13px 0 14px;
      border: 1px solid $borderColor;
      border-width: 1px 0 1px 1px;
      margin-left:-88px;
      font-size:99%;
      color:$borderColor;
      -webkit-transition: color .2s;
        transition: color .2s;
    }
    #main .mod_search .submit:hover {
      color:$ciCol;
    }
    .mod_search .radio {
        float:left;
        margin:3px 8px 0 0;
    }
    .submit:hover {
      cursor:pointer;
    }

    /* Results */
    .mod_search h3, .context {margin-bottom:11px;}
    .relevance {font-weight:400; color:$gray-font}

    /* General stuff */
    .textImg {display:flex;flex-direction: column-reverse}

    /*.cell {display:table-cell;vertical-align:middle;}*/
    #main .button_more a {color:#fff;}
    #main .button_more a:hover {color:$ciCol;}
    a.button_more,
    button.button_more {
      color: #fff;
      display: inline-block;
      padding: 9px;
      font-weight:$fontweightBold;
      border:2px solid #fff;
      background:rgba(0,0,0,0.4);
      -webkit-transition: background-color 2s;transition: background-color 2s;
    }
    a.button_more, button.button_more, #main .button_more a, .ce_form .submit {
      -webkit-transition: background-color .2s, color .2s;
      transition: background-color .2s, color .2s;
    }
    a.button_more:hover, button.button_more:hover, button.button_more.active {
      background-color:rgba(0,98,173,.8);
    }

    .push_left {margin-left: -30px; overflow: visible; }
    .push_right {margin-right: -30px; overflow: visible; }

    /* Downloads */
    /*
    .ce_download {
        margin-bottom: 36px;
    }
    */
    .download-element {
        background-position: left 2px;
        padding: 0 6px 0 22px;
    }
    .ce_downloads .download-element {
        padding-bottom: 5px;
    }
    /*
    .ce_downloads li {
        margin-top:3px;
    }
    .ce_downloads li:first-child {
        margin-top:0;
    }
    */

    /* Footer */
    #footer h3 {
        color:#fff;
        border-top:1px solid;
        border-bottom:1px solid;
        padding:11px 0;
    }
    #footer a {
        color:#fff;
    }
    /* social bookmarking icons */
    .ce_text .sb-icons {list-style:none;padding-left:0;}
    .ce_text .sb-icons li {padding:0;float:left}
    .sb-icons .last {width: 22px !important;}
    .sb-icons a {display:inline-block;width: 36px; height:36px;font-size:1.75em;color:transparent !important;}
    .sb-icons a:hover {text-decoration:none;}
    .f-icon:before {
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        speak: none;
        text-decoration: none;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        color: #fff;
    }
    .f-icon:hover:before {
        color:#eee;
    }
    .instagram:before {content:'\f16d';}
    .fb:before {content:'\f308';}
    .linkedin:before {content:'\f30c';}
    .xing:before {content:'\f169';}
    .youtube:before {content:'\f166';}

/*
.mail:before { content: '\e801'; }
.phone:before { content: '\e802'; }
*/

    .nomargins {margin-left:0 !important;margin-right:0 !important;}

    /* Entsorgungstechnik */
    .product-infos .wrapperStart {overflow:hidden}
    .products_back {padding-top:40px;text-align:center;}

    /* Golfpark */
    .event.layout_list h4 {margin-bottom:0}
    .event_date + p {margin-bottom:11px;}
    /* Senioren */
    .event_table tbody tr:nth-child(2n+1) {background:#eee;}
    .evt_date {
        white-space: nowrap;
        width: 5%;
    }
    .evt_location {
        width: 5%;
    }
    .event_table .new {display:inline-block;padding:0 3px;background:#900;color:#fff;font-size:10px;line-height:16px;font-weight:bold;}
    .event_table .hcp .evt_date:after {content:'HCP';display:inline-block;padding:0 3px;background:$ciCol;color:#fff;font-size:10px;line-height:16px;font-weight:bold;}
    #nl_form {
        max-width: 480px;
    }
    /* countdown */
    .cd_box {
        background: #fff;
        border-radius: 3px;
        color: #0062ad;
        display: inline-block;
        padding-bottom:3px;
    }
    .cd_amount {
        background: #fff;
        color: #0062ad;
        padding: 0 3px;
        display: block;
        border-radius:3px;
    }

    .cd_unit {
        font-size: 14px;
        display:block;
        font-weight:normal;
    }

    /* Geotherm */
    .infogallery {
        margin:0 -7px;
        overflow:hidden;
    }
    .infogallery > div {
        padding:0 7px;
        box-sizing:border-box;
    }

    .ce_text.inline-nav {
        margin-bottom: 2em;

        ul, li {
            display: inline-block;
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        a {
            display: inline-block;
            padding: 10px 20px;
            margin-right: 2px;
            color: #fff !important;
            background: #0062ad;
        }
    }

    .ce_rsce_contact {
        margin-bottom: 2em;
        border-top: 1px solid #ddd;

        .row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 5px 0;
            border-bottom: 1px solid #ddd;
        }

        .title {
            color: #0062ad;
            font-weight: bold;
        }

        a {
            display: flex;
        }

        @media (max-width: 600px), (min-width: 960px) {
            .title, a {
                width: 50%;
            }
        }

        img {
            height: 40px;
        }
    }

    .ce_rsce_hero {
        position: relative;

        h1 {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            padding: 0 1.6vw .4vw;
            color: $ciCol;
            font-size: 4vw;
            line-height: 1;
            letter-spacing: .2vw;
            white-space: pre;
            background: #fff;
        }
    }

    .headlineImg {
        text-align: center;
        border-top: 1.4vw solid $ciCol;
        padding: 4% 30px 0;

        img {
            display: inline-block;
            width: 100%;
            max-width: 890px;
        }
    }

    .cleverreach-newsletter {
        .widget {
            display: flex;
        }

        input {
            flex-grow: 1;
            padding: 2px 5px;
            color: #fff;
            background: transparent;
            border: 1px solid #fff;
        }

        button {
            margin: 0 0 0 10px;
            padding: 3px 10px;
            background: #fff;
            border: 0;
            color: $ciCol;
        }
    }

    @import "elements/generics";
    @import "elements/instagram";
    @import "elements/boatlist";
    @import "elements/text-image";
    @import "elements/social";
}

@import "elements/lehrstellen";
@import "elements/yousty";
@import "elements/downloads-slider";
@import "elements/locations";
