
$ciFont: 'myriad-pro-condensed,Arial,sans-serif' !default;
$ciCol: #0062ad !default;
$ciLetterSpacing: .08em !default;
$gray:#ebebeb;
$gray-hover:#F5F5F5;
$gray-font:#969696;

$borderColor:#ddd;

$fontCol: #000;
$headlineCol: #666;
$fontweightBold:600;
$lineHeight:22px;

// Myriad Pro Condensed from Adobe Fonts
$fontUrl: 'https://use.typekit.net/dnc8whl.css' !default;
@import url($fontUrl);

@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.eot?67158415');
    src: url('../fonts/fontello.eot?67158415#iefix') format('embedded-opentype'),
    url('../fonts/fontello.woff2?67158415') format('woff2'),
    url('../fonts/fontello.woff?67158415') format('woff'),
    url('../fonts/fontello.ttf?67158415') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@mixin screen($size, $block: null) {
    $sizes: (
        sm: "min-width: 480px",
        md: "min-width: 768px",
        lg: "min-width: 1024px",
        xl: "min-width: 1200px",
        xxl: "min-width: 1620px",
    );

    @if (map-has_key($sizes, $size)) {
        $size: map-get($sizes, $size);
    } @else if (type_of($size) == 'number') {
        $size: "min-width: #{$size}px";
    }

    @media screen and (#{unquote($size)}) {
        @if ($block) {
            #{$block} {
                @content;
            }
        } @else {
            @content;
        }
    }
}

@mixin border-box {
    box-sizing: border-box;

    *, *:before, *:after {
        box-sizing: inherit;
    }
}
