@import 'globals';

@media screen {
    #wrapper {
        position: relative;
        overflow: hidden;
        min-height: 100%;
        min-width: 240px;
        box-sizing: border-box;
    }

    .centerBox {
        margin: 0 auto;
        max-width: 1600px;
        overflow: hidden;
    }
    .centerBox {
        padding: 0 30px;
        position: relative;
    }
    #header {
        margin-bottom: 60px;
    }
    .home #header {
        margin-bottom: 0;
    }

    #logo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        min-height: 152px;

        a {
            display: block;
        }

        img {
            width: auto !important;
            max-height: 166px; // Remove when all layouts are updated
            max-width: 520px;
        }
    }

    #topBar {
        background-color: $ciCol;
        color: #fff;
        padding: 5px 30px;
        font-size: 0.7857142857142857em;
        line-height: 20px;
        font-weight: $fontweightBold;
        text-align: center;

        p,
        ul {
            margin-bottom: 5px;
        }
    }

    #slider-full {
        background: $gray;
        position: relative;
        border-bottom: 10px solid $ciCol;
    }
    #slider-full .ce_sliderStart {
        max-width: 1620px;
        margin: 0 auto;
    }
    #slider-full .image_container, #slider-full .ce_youtube {
        margin-bottom: 0;
    }

    /* columns */
    .rs-columns {
        margin: 0 -30px;
        overflow: hidden
    }
    .rs-column {
        box-sizing: border-box;
        padding: 0 30px;
    }
    .rs-columns.flex {
        display: flex;
        flex-wrap: wrap
    }
    .rs-columns.flex .rs-column.align-center {
        align-self: center;
    }
    /*
    .-large-col-4-1 {width:25%}
    .-large-col-4-3 {width:75%}
    */
    /* Spezial elemente */
    .colored {
        margin: 0 -30px;
    }
    .colored .rs-columns {
        margin: 0;
    }
    .colored:nth-child(odd), .mod_article.colored {
        background-color: #eee
    }
    .colored .rs-column .image_container, .colored .rs-column > div {
        padding: 30px 30px 0;
    }
    .colored .image_container {
        margin-bottom: 0;
    }
    /*
    .colored .-large-col-3-1:first-child {padding:$lineHeight 30px $lineHeight 0;}
    .colored .-large-col-3-1:last-child {padding:$lineHeight 0 $lineHeight 30px;}
    */
    .colored .txtBox {
        padding-top: 22px;
        vertical-align: middle;
    }
    .colored .image-container img {
        width: 100%;
        max-width: unset;
    }
    .colored .-large-col-3-1 {
        display: table-header-group;
    }
    .colored .-large-col-3-2 {
        display: table-footer-group;
    }

    /*
    #main .mod_article.max-1200 > * {max-width:1200px;margin-left:auto;margin-right:auto;}
    #main .mod_article.max-800 > * {max-width:780px;margin-left:auto;margin-right:auto;}

    #main .mod_article.max-1200 {max-width:1260px;margin-left:auto;margin-right:auto;}
    #main .mod_article.max-800 {max-width:840px;margin-left:auto;margin-right:auto;}
                    */
    .wrapperStart {
        margin-left: auto;
        margin-right: auto; /*padding-left:30px;padding-right:30px;*/
    }
    .wrapper_medium {
        max-width: 1260px;
    }
    .wrapper_small {
        max-width: 840px;
    }


    .linie-oben {
        margin-top: 40px;
        border-top: 1px solid $gray;
        padding-top: 40px;
    }
    .lines_ce > .textImg {
        border-bottom: 1px solid $gray;
        padding-bottom: 40px;
        margin-bottom: 40px;
    }

    #container {
        min-height: 220px;
        /* margin-top:40px; */
        margin-bottom: 76px;
    }

    #main {
        float: none;
        margin: 0 auto;
    }
    #main .mod_article {
        /*
        max-width: 1560px;
        padding-left:30px;
        padding-right:30px;
        */
        padding-left: 30px;
        padding-right: 30px;
        margin: 0 auto 22px;
    }
    #main .mod_article.lines_ce {
        margin-bottom: 0;
    }
    /*
    #main .mod_article > div[class^=ce_] {
      margin-left: 30px;
      margin-right: 30px;
    }
    */

    #main .mod_article.fullWidth {
        max-width: none;
    }
    #main .mod_article.fullWidth > div {
        margin-left: -30px;
        margin-right: -30px;
    }
    /*
.fullWidth .ce_text, .fullWidth .ce_image {
    max-width: 1310px;
    padding:0 20px;
    margin:0 auto;
}
.fullWidth .ce_gallery {
    max-width: 1310px;
    margin:0 auto;
}
*/

    .layout_cols_m_r #container {
        max-width: 1200px;
        margin: 0 auto;
    }

    .layout_cols_m_r #main .inside, #right .inside {
        padding: 0 30px;
    }
    .layout_cols_m_r #main .mod_article {
        padding: 0
    }
    #right {
        width: 100%
    }
    #right h2 {
        border: 1px solid $borderColor;
        border-width: 1px 0;
        font-size: 1.142857142857143em; /* 16px */
        padding: 10px 0;
        letter-spacing: 1px;
    }
    #right div[class^=mod_] {
        margin-bottom: $lineHeight;
    }

    .extra-leerzeile {
        margin-bottom: 1.5em !important;
    }
    .extra-leerzeilen-2 {
        margin-bottom: 3em !important;
    }
    .extra-leerzeilen-3 {
        margin-bottom: 4.5em !important;
    }

    /* -- F O O T E R -- */
    #footer {
        padding: 0 30px;
        background: $ciCol;
        color: #fff;

        .inside {
            max-width: 1560px;
            margin: 0 auto;
            overflow: hidden;
        }

        .rs-columns {
            margin-top: 60px;
            margin-bottom: 36px;
        }

        .wrapperStart {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px 40px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}
