.layout_locations .wrapper_medium {max-width: 1140px;}

.mod_location_search {
    @media screen and (min-width: 800px) {
        &__form {
            display: flex;
            gap: 20px;
            align-items: center;

            > * {
                width: 25%;
            }

            select {
                margin-bottom: 0;
            }
        }
    }

    &__submit {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 25px;
    }

    &__results {
        margin-top: 33px;
    }

    &__map {
        aspect-ratio: 16/9;
    }
}

.mod_location_search,
.mod_location_details {
    .leaflet-layer {
        filter: grayscale(100);
    }

    .leaflet-control-attribution {
        font-size: 12px;
    }
}

.mod_location_details {
    max-width:1140px;
    margin-left:auto;
    margin-right:auto;
}
