.content-social {
    ul, li {
        margin: 0;
        padding: 0;
    }

    ul {
        list-style: none;
        display: flex;
        gap: 8px;
    }

    &__icon {
        display: block;
        width: 28px;
        height: 28px;
        text-indent: -999em;

        $icons: (
            'instagram',
            'facebook',
            'youtube',
            'tiktok',
            'linkedin',
            'xing',
        );

        @each $icon in $icons {
            &--#{$icon} {
                background: url("../../images/social-#{$icon}--footer.svg") 0 0/contain no-repeat;
            }
        }
    }
}
