@import 'globals';

@media print {
    #slider-full, .textImg_home .image_container, p.button_more, #news_home, .streifen .image_container, #langNav, #searchForm, .mod_search > form, #customerNav, #mainNav, .back, .layout_full .ce_gallery, .more, .pagination, .mod_rocksolid_slider, .mod_fmodule_fe_formfilter, .mod_fmodule_fe_list .google_map, #footer, #topLink, .noprint, .enclosure, .mod_newsmenu, .ce_form, .slider-control, #slider .ce_hyperlink, .ce_player {
        display: none;
    }

    body {
        font: 10pt unquote($ciFont);
        color: #000;
    }

    h1,h2 {
        color: $ciCol;
    }
    h3,h4 {
        color: #000;
    }

    img {
        border-style: none;
    }

    table {
        border-collapse: collapse;
        border: 1px solid #000;
    }

    th, td {
        vertical-align: top;
        text-align: left;
        padding: 2px;
        border: 1px solid #000;
    }

    th {
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    b, strong {
        font-weight: bold;
    }

    #logo {
        margin-bottom:30pt;
    }
}
