#main .mod_article.background-gray {
    background:$gray;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.ce_text.center,
.ce_hyperlink.center,
.ce_image.center {
    text-align: center;
}

.ce_text.right,
.ce_hyperlink.right,
.ce_image.right {
    text-align: right;

    img {
        display: inline;
        width: auto;
    }
}

.ce_text > h1:not(.ci) {
    font-size: 2em;
    line-height: 1.2;
    color:$ciCol;
}

.refline-desktop {
    display: none;
}
@media screen and (min-width: 600px) {
    .refline-mobile {
        display: none;
    }
    .refline-desktop {
        display: block;
    }
}


/* KIBAG Marina Preisliste (https://www.kibagmarina.ch/preview.php/de/bootsverkauf.html) */
@media screen and (min-width: 960px) {
    .pricelist {
        > :first-child {
            float: left;
            width: 320px;
            margin: 0 50px 20px 0;
        }
    }
}
