$this: '.ce_rsce_lehrstellen';

#{$this} {
    h2 {
        margin: 40px 0 20px;
        text-align: center;
    }

    &__filters {
        text-align: center;
    }

    &__filter {

    }

    &__jobs {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    &__job {
        width: 100%;

        &-inside {
            display: flex;
            flex-direction: column;
            margin: 0 15px 30px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &__title {
        @extend .h2;
        padding: 5px;
        font-size: 1.14286em;
        background: $gray;

        span:last-child {
            font-weight: normal;
        }
    }
}

@include screen(sm, $this) {
    &__job {
        width: 50%;
    }
}

@include screen(md, $this) {
    &__job {
        width: 33%;
    }
}
